@import 'src/assets/scss/variable.scss';

.phuket-page {
  .phuket-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px 20px;

    .phuket-title {
      font-size: 24px;
      font-weight: 600;
      color: $color-white;
      margin-bottom: 30px;
      text-align: center;
    }

    .text-field-grid {
      margin-bottom: 20px;

      .matrix-textfield {
        background-color: $color-dark-gray;
        border-radius: 4px;

        .MuiInputBase-root {
          color: $color-white;
        }

        .MuiInputLabel-root {
          color: $color-light-gray;
        }

        .MuiFilledInput-underline:before,
        .MuiFilledInput-underline:after {
          border-bottom-color: $color-primary;
        }
      }
    }

    .user-type-label {
      font-size: 16px;
      font-weight: 500;
      color: $color-white;
      margin-bottom: 10px;
    }

    .MuiFormControlLabel-root {
      margin-right: 20px;
      margin-bottom: 10px;

      .MuiCheckbox-root {
        color: $color-primary;
      }

      .MuiTypography-root {
        color: $color-white;
      }
    }

    .error-message {
      color: $color-error;
      font-size: 12px;
      margin-top: 5px;
    }

    .submit-button {
      background-color: $color-primary;
      color: $color-white;
      font-weight: 600;
      padding: 10px 0;
      margin-top: 20px;

      &:hover {
        background-color: darken($color-primary, 10%);
      }
    }
  }
}