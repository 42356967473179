// Fonts
$authHeaderFont: Staatliches, Alumni Sans, sans-serif;
$primaryFont: Roboto, sans-serif;

// Colors
$color-black: #000000;
$color-spanish-gray: #9d9d9d;
$color-gray-dark: #3d3e3f;
$color-lava: #707070;
$color-blue: #438ac1;
$color-light-blue: #d9e8f3;
$color-light-black: #202020;
$color-dark-black: #101010;
$color-dark-gray: #333333;
$color-light-gray: #cccccc;
$color-black-very-light: #231f20;
$color-primary: #007bff; // Adjust this to match your primary color
$color-error: #ff0000;
$color-white: #ffffff;
$color-pearl: #b4babf;
$color-light-pearl: #adbdc2;
$color-lime-black: #1b1b1b;
$color-fb: #3b5998;
$color-twitter: #55acee;
$color-link: #007bb5;
$color-check: #438ac1;
$color-pin: #cb2027;
$color-wp: #25d366;
$color-text-deco: #e5e5e5;
$color-msg-left: #2e353a;


// Backgrounds
$blockColor: #171717;

// Border color
$blockBorder: 1px solid #2e353a;

// Border Radius
$bRadius: 3px;

// BreakPoint
$mobileBreakPoint: 1020px
